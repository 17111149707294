import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations, ToolbarTypes } from "@/store/enums/StoreEnums";

interface Breadcrumb {
    title: string;
    pageBreadcrumbPath: Array<string>;
    toolbarType: ToolbarTypes;
}

interface StoreInfo {
    breadcrumbs: Breadcrumb;
    toolbarProps: any;
}

@Module
export default class BreadcrumbsModule extends VuexModule implements StoreInfo {
    breadcrumbs = {} as Breadcrumb;
    toolbarProps = {};

    /**
     * Get breadcrumb object for current page
     * @returns object
     */
    get getBreadcrumbs(): Breadcrumb {
        return this.breadcrumbs;
    }

    /**
     * Get breadcrumb object for current page
     * @returns object
     */
    get getToolbarProps() {
        return this.toolbarProps;
    }

    /**
     * Get breadcrumb array for current page
     * @returns object
     */
    get pageBreadcrumbPath(): Array<string> {
        return this.breadcrumbs.pageBreadcrumbPath;
    }

    /**
     * Get current page title
     * @returns string
     */
    get pageTitle(): string {
        return this.breadcrumbs.title;
    }

    get toolbarType(): string {
        return this.breadcrumbs.toolbarType;
    }

    @Mutation
    [Mutations.SET_BREADCRUMB_MUTATION](payload) {
        if (payload.toolbarType == undefined || payload.toolbarType == null) {
            payload.toolbarType = ToolbarTypes.DEFAULT;
        }
        this.breadcrumbs = payload;
    }

    @Mutation
    [Mutations.SET_TOOLBAR_PROPS](payload) {
        this.toolbarProps = payload;
    }

    @Action
    [Actions.SET_BREADCRUMB_ACTION](payload) {
        this.context.commit(Mutations.SET_BREADCRUMB_MUTATION, payload);
    }

    @Action
    [Actions.SET_TOOLBAR_TYPE](payload) {
        this.breadcrumbs = { ...this.breadcrumbs, toolbarType: payload };
    }

    @Action({ rawError: true })
    [Actions.SET_TOOLBAR_PROPS](payload) {
        this.context.commit(Mutations.SET_TOOLBAR_PROPS, payload);
    }
}
