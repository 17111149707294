import store from "@/store";
import { USER_GRANTS, ROLES } from "@/core/config/AppConfig";
import { isEmpty } from "lodash";

// AuthModule.getCurrentRole.grants

export const hasPermission = (grant, params) => {
    switch (grant) {
        case USER_GRANTS.TASSA_SOGGIORNO:
            return isGrantedOnCityTax(params?.accommodationId);

        case USER_GRANTS.FILE_UPLOAD_ISTAT:
            return isGrantedOnFileUpload();

        case USER_GRANTS.SBLOCCO_OSPITI:
            return isGrantedOnSbloccoOspiti();

        case USER_GRANTS.RIAPERTURA_MESE:
            return isGrantedOnReopenMonth();

        default:
            return isGrantedOn(grant);
    }

    // if ( action_name == USER_GRANTS.TASSA_SOGGIORNO){
    //     return isGrantedOnCityTax(accommodation_id);
    // }

    // if ( action_name == USER_GRANTS.FILE_UPLOAD_ISTAT){
    //     return isGrantedOnFileUpload();
    // }

    // return isGrantedOn( action_name )
};

export const isAdminOrProvincia = () => {
    return (
        store.getters.currentRole.name === ROLES.admin ||
        store.getters.currentRole.name === ROLES.provincia
    );
};
export const isAdmin = () => {
    return (
        store.getters.currentRole.name === ROLES.admin 
    );
};

const isGrantedOn = (action_name, forRole = false) => {
    if (!store.getters.currentRole || isEmpty(store.getters.currentRole.grants))
        return false;

    return (
        store.getters.currentRole.grants.filter((g) => g.code == action_name)
            .length > 0
    );
};

/**
 * verifica se per una specifica accommodation è in un comune che applica la tassa di soggiorno
 *
 */
const isGrantedOnCityTax = (accommodation_id) => {
    
    if (store.getters.currentRole.name == ROLES.consorzio ){
        return isGrantedOn(USER_GRANTS.TASSA_SOGGIORNO);
    }
    
    
    if (
        !store.getters.currentRole ||
        !store.getters.currentManager?.accommodations
    )
        return false;

    // verifica sulla pratica
    const currentAccommodation =
        store.getters.currentManager.accommodations.filter(
            (a) => a.id == accommodation_id
        )[0];

    // verifico se il comune dell'accommodation corrente applica la tassa di soggiorno
    if (
        !currentAccommodation ||
        !currentAccommodation.hasTax ||
        currentAccommodation.hasTax.length == 0
    )
        return false;

    if (
        store.getters.currentRole.name == ROLES.hotel ||
        store.getters.currentRole.name == ROLES.admin
    )
        return true;
    else return isGrantedOn(USER_GRANTS.TASSA_SOGGIORNO);
};

/**
 * permessi di file upload richiedono permesso esplicito per utenti dlelegati e aver configurato il PMS
 *
 */
const isGrantedOnFileUpload = () => {
    // verificare la presenza di un PMS_ID configurato

    if (
        store.getters.currentRole.name == ROLES.hotel ||
        store.getters.currentRole.name == ROLES.admin ||
        store.getters.currentRole.name == ROLES.consorzio
    )
        return true;

    return isGrantedOn(USER_GRANTS.FILE_UPLOAD_ISTAT);
};

/**
 * permessi di file upload richiedono permesso esplicito per utenti dlelegati e aver configurato il PMS
 *
 */
const isGrantedOnReopenMonth = () => {
    if (
        store.getters.currentRole.name == ROLES.admin ||
        store.getters.currentRole.name == ROLES.provincia
    )
        return true;

    return isGrantedOn(USER_GRANTS.RIAPERTURA_MESE);
};

const isGrantedOnSbloccoOspiti = () => {
    if (
        store.getters.currentRole.name == ROLES.admin ||
        store.getters.currentRole.name == ROLES.provincia
    )
        return true;

    return isGrantedOn(USER_GRANTS.SBLOCCO_OSPITI);
};
