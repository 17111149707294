import { MANAGER_TYPES, ROLES } from "@/core/config/AppConfig";

export default {
    path: "comune/",
    component: () => import("@/layout/RouterViewWrapper.vue"),
    name: "comune",
    meta: {
        guards: {
            types: [MANAGER_TYPES.comune],
            roles: [ROLES.comune],
        },
    },
    children: [
        {
            path: "",
            name: "comune.dashboard",
            component: () => import("@/views/DashboardComune.vue"),
        },
        {
            path: "accommodations",
            name: "comune.accommodations",
            component: () =>
                import("@/views/accommodations/admin/AccommodationsList.vue"),
        },
        /* Pratiche */
        {
            path: "licenses",
            name: "comune.licenses.archive",
            component: () => import("@/views/licence/LicenceList.vue"),
        },
        {
            path: "licenses/:licenseId",
            name: "comune.licenses.archive.detail",
            component: () => import("@/views/licence/LicenseDetail.vue"),
        },
        /*Managers*/
        {
            path: "licenses/managers",
            name: "comune.licenses.managers",
            component: () => import("@/views/licence/ManagersList.vue"),
            props: { type: "hotel" },
        },
        {
            path: "licenses/managers/:managerDetailId",
            name: "comune.licenses.managers.detail",
            component: () => import("@/views/licence/ManagerDetail.vue"),
            children: [
                {
                    path: "users/",
                    name: "comune.licenses.managers.detail.user.create",
                    props: {
                        withPermissions: false,
                    },
                    component: () =>
                        import("@/components/abit/users/UserFormModal.vue"),
                },
                {
                    path: "users/:userId",
                    name: "comune.licenses.managers.detail.user.edit",
                    component: () =>
                        import("@/components/abit/users/UserFormModal.vue"),
                },
            ],
        },
        {
            path: "applications",
            name: "comune.applications",
            component: () => import("@/views/licence/ApplicationList.vue"),
        },
        {
            path: "license/properties",
            name: "comune.licenses.properties",
            component: () => import("@/views/licence/PropertiesList.vue"),
        },

        {
            path: "citytax",
            name: "comune.citytax.summary",
            component: () => import("@/views/citytax/CitytaxSummary.vue"),
        },
        {
            path: "citytax/guests",
            name: "comune.citytax.guests",
            component: () => import("@/views/citytax/CitytaxGuests.vue"),
        },
        {
            path: "citytax/setup",
            name: "comune.citytax.setup",
            component: () => import("@/views/citytax/Setup.vue"),
        },
        {
            path: "citytax/reports",
            name: "comune.citytax.reports",
            component: () => import("@/views/citytax/Reports.vue"),
        },
        {
            path: "analytics/modello-21",
            name: "comune.analytics.modello-21",
            component: () => import("@/views/analytics/ComuneModello21.vue"),
            meta: {
                guards: {
                    types: [MANAGER_TYPES.comune,MANAGER_TYPES.mandamento,MANAGER_TYPES.destinazione],
                    roles: [ROLES.comune,ROLES.consorzio],
                },
            },
        },
        {
            path: "citytax/rates",
            name: "comune.citytax.rates",
            component: () => import("@/views/citytax/Rates.vue"),
        },
        {
            path: "citytax/rates/:id",
            name: "comune.citytax.new_rate",
            component: () => import("@/views/citytax/RatesForm.vue"),
        },
        {
            path: "citytax/exemptions",
            name: "comune.citytax.exemptions",
            component: () => import("@/views/citytax/Exemptions.vue"),
        },
        {
            path: "citytax/exemptions/:id",
            name: "comune.citytax.new_exemption",
            component: () => import("@/views/citytax/ExemptionsForm.vue"),
        },
        {
            path: "citytax/cityrules",
            name: "comune.citytax.cityrules",
            component: () => import("@/views/citytax/CityRules.vue"),
        },
        {
            path: "citytax/docs",
            name: "comune.citytax.docs",
            component: () => import("@/views/citytax/CityDocs.vue"),
        },
        {
            path: "analytics/guests",
            name: "comune.analytics-guests",
            component: () => import("@/views/analytics/ReportGuests.vue"),
        },
        {
            path: "analytics/origin",
            name: "comune.analytics-origin",
            component: () => import("@/views/analytics/ReportOrigin.vue"),
        },
        {
            path: "analytics/accommodations",
            name: "comune.analytics-accommodations",
            component: () =>
                import("@/views/analytics/ReportAccommodations.vue"),
        },
        {
            path: "analytics/citytax",
            name: "comune.analytics-citytax",
            component: () => import("@/views/analytics/ReportCitytax.vue"),
        },
    ],
};
