import { MANAGER_TYPES, ROLES, USER_GRANTS } from "@/core/config/AppConfig";

export default {
    path: "consorzio/",
    component: () => import("@/layout/RouterViewWrapper.vue"),
    name: "consorzio",
    meta: {
        guards: {
            types: [MANAGER_TYPES.mandamento, MANAGER_TYPES.destinazione],
            roles: [ROLES.consorzio],
        },
    },
    children: [
        {
            path: "",
            name: "consorzio.dashboard",
            component: () => import("@/views/DashboardConsorzio.vue"),
        },
        {
            path: "users",
            name: "consorzio.users",
            component: () => import("@/views/users/UsersList.vue"),
        },
        {
            path: "users/:userId",
            name: "consorzio.users.detail",
            component: () => import("@/views/users/UserDetail.vue"),
        },
        {
            path: "managers", // V
            name: "consorzio.licenses.managers",
            component: () => import("@/views/licence/ManagersList.vue"),
            props: { type: "hotel" },
        },
        {
            // V
            path: "managers/:managerDetailId",
            name: "consorzio.licenses.managers.detail",
            component: () => import("@/views/licence/ManagerDetail.vue"),
            children: [
                {
                    path: "users/",
                    name: "consorzio.licenses.managers.detail.user.create",
                    props: {
                        withPermissions: false,
                    },
                    component: () =>
                        import("@/components/abit/users/UserFormModal.vue"),
                },
                {
                    path: "users/:userId",
                    name: "consorzio.licenses.managers.detail.user.edit",
                    props: {
                        withPermissions: false,
                    },
                    component: () =>
                        import("@/components/abit/users/UserFormModal.vue"),
                },
            ],
        },

        {
            path: "accommodations",
            name: "consorzio.accommodations",
            component: () =>
                import("@/views/accommodations/admin/AccommodationsList.vue"),
        },
        {
            path: "accommodations/:accommodationId/detail",
            name: "consorzio.accommodations.detail",
            component: () =>
                import("@/views/accommodations/admin/AccommodationDetail.vue"),
        },
        {
            path: "accommodations/license/:licenseId",
            name: "consorzio.accommodations.license.new",
            component: () =>
                import("@/views/accommodations/new/NewAccommodation.vue"),
            meta: {
                guards: {
                    grant: USER_GRANTS.GESTIONE_PRATICHE_ATTIVITA_RICETTIVE,
                },
            },
        },
        {
            path: "accommodations/licenses",
            name: "consorzio.accommodations.licenses",
            component: () =>
                import("@/views/accommodations/UnpairedLicensesList.vue"),
            meta: {
                guards: {
                    grant: USER_GRANTS.GESTIONE_PRATICHE_ATTIVITA_RICETTIVE,
                },
            },
        },
        {
            path: "licences",
            name: "consorzio.licenses.archive",
            component: () => import("@/views/licence/LicenceList.vue"),
        },
        {
            path: "license/:licenseId",
            name: "consorzio.licenses.archive.detail",
            component: () => import("@/views/licence/LicenseDetail.vue"),
        },

        {
            path: "istat",
            name: "consorzio.istat.guests",
            component: () => import("@/views/istat/GuestManager.vue"),
        },
        {
            path: "istat/totals",
            name: "consorzio.istat.chiusura-massiva",
            component: () => import("@/views/istat/MonthlyMassEnding.vue"),
            meta: {
                guards: {
                    grant: USER_GRANTS.CHIUSURA_MASSIVA,
                },
            },
        },
        {
            path: "istat/rispondenti",
            name: "consorzio.istat.rispondenti",
            component: () => import("@/views/istat/ReportRispondenti.vue"),
        },
        {
            path: "istat/report_full",
            name: "consorzio.istat.report_full",
            component: () => import("@/views/istat/ReportRispondentiFull.vue"),
        },
        {
            path: "istat/fileupload",
            name: "consorzio.istat.fileupload",
            component: () =>
                import("@/views/istat/ConsorzioFileuploadList.vue"),
        },
        {
            path: "istat/closing-calendar",
            name: "consorzio.istat.closing-calendar",
            component: () => import("@/views/istat/ClosingCalendarList.vue"),
        },
        {
            path: "istat/big_brother",
            name: "consorzio.istat.big-brother",
            component: () => import("@/views/istat/BigBrother.vue"),
        },
        {
            path: "istat/export",
            name: "consorzio.istat.export",
            component: () => import("@/views/istat/Export.vue"),
        },
        {
            path: "analytics/guests",
            name: "consorzio.analytics.guest-nights",
            component: () => import("@/views/analytics/ReportGuests.vue"),
        },
        {
            path: "analytics/accommodation",
            name: "consorzio.analytics.accommodations",
            component: () =>
                import("@/views/analytics/ReportAccommodations.vue"),
        },
        {
            path: "analytics/origin",
            name: "consorzio.analytics.origin",
            component: () => import("@/views/analytics/ReportOrigin.vue"),
        },
        {
            path: "analytics/studi-settore",
            name: "consorzio.analytics.studi-settore",
            component: () => import("@/views/analytics/ReportStudiSettore.vue"),
        },
        {
            path: "citytax",
            name: "consorzio.citytax.summary",
            component: () => import("@/views/citytax/CitytaxSummary.vue"),
            meta: {
                guards: {
                    grant: USER_GRANTS.TASSA_SOGGIORNO,
                },
            },
        },
        {
            path: "citytax/reports",
            name: "consorzio.citytax.reports",
            component: () => import("@/views/citytax/Reports.vue"),
            meta: {
                guards: {
                    grant: USER_GRANTS.TASSA_SOGGIORNO,
                },
            },
        },        
    ],
};
