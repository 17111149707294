import { InputOption } from "@/core/data/interfaces";
import { ObjectSchema } from "yup";

export interface IFormBuilderProps {
    fields: IFormBuilderItem[];
    inline?: boolean;
    compact?: boolean;
    loading?: boolean;
    submitLabel?: string;
    exitLabel?: string;
    deleteLabel?: string;
    submitClass?: string;
    deleteClass?: string;
    handled?: boolean;
    disabled?: boolean;
    show_password?: boolean;
    show_exit_button?: boolean;
    show_delete_button?: boolean;
    initialValues?: Record<string, any>;
    validation?: ObjectSchema<any> | undefined;
}

export enum AbitInputs {
    Text = "abit-text-input",
    Password = "abit-password-input",
    DatePicker = "abit-date-picker",
    Radio = "abit-radio",
    Select = "abit-select",
    Editor = "abit-editor",
    Cities = "abit-cities",
    Switch = "abit-switch",
    CheckList = "abit-check-list",
    ImageUpload = "abit-image-upload",
    DropAutocomplete = "abit-drop-auto-complete",
    Span = "abit-span",
    AccommodationTypes = "abit-accommodation-types",
}

export interface IFormBuilderItemBasic {
    id: string;
    label: string;
    props?: Record<string, unknown>;
    events?: any;
    className?: string;
}

export interface IFormBuilderItemGeneric extends IFormBuilderItemBasic {
    type?: Exclude<AbitInputs, AbitInputs.Select | AbitInputs.Radio>;
}
export interface IFormBuilderItemSelect extends IFormBuilderItemBasic {
    type: AbitInputs.Select | AbitInputs.Radio | AbitInputs.CheckList;
    options: InputOption[] | { [key: string]: string }[];
}

export type IFormBuilderItem = IFormBuilderItemGeneric | IFormBuilderItemSelect;

export enum ProcessingStatus {
    WAITING = "waiting",
    PROCESSING = "processing",
    SUCCESS = "success",
    FAILED = "failed",
}
export interface ProcessingList {
    [id: number]: ProcessingStatus;
}
