import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { MANAGER_TYPES } from "@/core/config/AppConfig";
import router from "@/router";
import { IGrant } from "@/core/data/interfaces";
import { checkGuard } from "@/core/helpers/navigation";
import {
    getManagerTypeBaseRoute,
    searchCurrentManager,
} from "@/core/helpers/managers";
import { Guard } from "@/router/types";

export interface User {
    name: string;
    username: string;
    id: number;
    email: string;
    cf: string;
    managers: Manager[];
    role: Role;
    impersona: boolean;
}

export interface Manager {
    name: string;
    id: number;
    level: string;
    type: MANAGER_TYPES;
    items: any[];
    grants: IGrant[];
    role: string;
    isHotel?: boolean;
}

export interface Role {
    name: string;
    grants: IGrant[];
}

export interface UserAuthInfo {
    errors: unknown;
    user: User;
    isAuthenticated: undefined | boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
    errors = false;
    user = {} as User;
    manager: number | false = false;
    isAuthenticated;

    /**
     * Get current user object
     * @returns User
     */
    get currentUser(): User {
        return this.user;
    }

    /**
     * Get current role object
     * @returns User
     */
    get currentRole(): Role {
        if (this.user.managers == undefined) return {} as Role;

        const currentManager = this.user.managers.find(
            (i) => i.id === this.manager
        );

        return !currentManager
            ? ({} as Role)
            : {
                  name: currentManager.role,
                  grants: currentManager.grants,
              };
    }

    /**
     * Get current manager
     * @returns User
     */
    get currentManager(): Manager | undefined {
        if (this.user.managers == undefined) return;

        return searchCurrentManager(this.user.managers, this.manager);
    }

    get currentManagerBaseRoute(): string | undefined {
        if (!this.currentManager) return;

        return getManagerTypeBaseRoute(this.currentManager.type);
    }

    /**
     * Get current role object
     * @returns User
     */
    get managers(): Manager[] {
        return this.user?.managers || [];
    }

    /**
     * Verify user authentication
     * @returns boolean
     */
    get isUserAuthenticated(): boolean {
        return this.isAuthenticated;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getErrors() {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_AUTH](user) {
        this.isAuthenticated = true;

        //user.role = this.user.role;

        this.user = {
            ...user,
            managers: (user?.managers || []).map((i) => ({
                ...i,
                isHotel: [
                    MANAGER_TYPES.privato,
                    MANAGER_TYPES.azienda,
                ].includes(i.type),
            })),
        };
        this.errors = false;
    }

    @Mutation
    [Mutations.SET_USER](user) {
        this.user = user;
    }

    @Mutation
    [Mutations.PURGE_AUTH]() {
        this.isAuthenticated = false;
        this.user = {} as User;
        this.errors = false;
    }

    @Mutation
    [Mutations.SET_ROLE](role) {
        this.user.role = {
            name: role,
            grants: [],
        } as Role;
    }

    @Mutation
    [Mutations.SET_MANAGER](managerId) {
        this.manager = managerId;
    }

    @Mutation
    [Mutations.IMPERSONATE](payload) {
        this.user.impersona = payload;
    }

    @Action
    [Actions.LOGIN](credentials) {
        return ApiService.post("login", credentials)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_AUTH, data);
            })
            .catch(({ response }) => {
                this.context.commit(
                    Mutations.SET_ERROR,
                    response?.data?.message || "Impossibile effettuare login"
                );
            });
    }

    @Action
    [Actions.LOGOUT]() {
        return ApiService.post("logout", {})
            .then(() => {
                this.context.commit(Mutations.PURGE_AUTH);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.REGISTER](credentials) {
        return ApiService.post("register", credentials)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_AUTH, data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.FORGOT_PASSWORD](payload) {
        return ApiService.post("forgot-password", payload)
            .then(() => {
                this.context.commit(Mutations.SET_ERROR, {});
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }

    @Action
    [Actions.VERIFY_AUTH]() {
        ApiService.setHeader();
        return ApiService.get("/profile")
            .then(({ data }) => {
                //  router.replace({ name: "manager" });

                this.context.commit(Mutations.SET_AUTH, {
                    ...data.content,
                });
            })
            .catch((error) => {
                const { response } = error;

                this.context.commit(Mutations.SET_ERROR, response.data.errors);
                this.context.commit(Mutations.PURGE_AUTH);
            });
    }

    @Action({ rawError: true })
    async [Actions.VERIFY_GRANT]({
        managerId,
        guards,
        params,
    }: {
        managerId: string;
        guards: Guard;
        params;
    }) {
        await this.context.commit(Mutations.SET_MANAGER, parseInt(managerId));

        const currentManager = this.context?.getters?.currentManager;

        //TODO: In caso di reload senza passare da select-manager currentRole è undefined
        if (!checkGuard(guards, currentManager, params)) router.replace("/403");
    }

    @Action
    async [Actions.IMPERSONATE](payload) {
        await this.context.commit(Mutations.IMPERSONATE, {
            impersona: payload,
        });
    }
}
