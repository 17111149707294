import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import admin from "./admin";
import provincia from "./provincia";
import hotel from "./hotel";
import consorzio from "./consorzio";
import comune from "./comune";
import profile from "./profile";
import common from "./common";

import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import _startsWith from "lodash/startsWith";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        redirect: "/select-managers",
    },
    {
        // the 404 route, when none of the above matches
        path: "/select-managers",
        name: "manager",
        component: () => import("@/views/ManagerSelection.vue"),
    },
    {
        path: "/manager/:managerId/",
        component: () => import("@/layout/Layout.vue"),
        children: [admin, provincia, hotel, consorzio, comune, profile, common],
    },
    {
        path: "/",
        component: () => import("@/components/page-layouts/Auth.vue"),
        children: [
            {
                path: "/sign-in",
                name: "login",
                component: () => import("@/views/auth/SignIn.vue"),
            },
            {
                path: "/password-dimenticata",
                name: "password-forget",
                component: () => import("@/views/auth/PasswordForget.vue"),
            },
            {
                path: "/password/reset",
                name: "password-reset",
                component: () => import("@/views/auth/PasswordReset.vue"),
            },
            {
                path: "/signup/request",
                name: "request-signup",
                component: () => import("@/views/auth/RequestSignUp.vue"),
            },
        ],
    },
    {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
    },
    {
        // the 404 route, when none of the above matches
        path: "/403",
        name: "403",
        component: () => import("@/views/crafted/authentication/Error403.vue"),
    },
    {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/404",
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    // reset config to initial state
    //store.commit(Mutations.RESET_LAYOUT_CONFIG);
    console.log("ROUTER BEFOREACH", to);
    if (to.meta?.guards) {
        store.dispatch(Actions.VERIFY_GRANT, {
            managerId: to.params.managerId,
            guards: to?.meta.guards,
            params: to.params,
        });
    }
    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);

    const impersonate = keepImpersonateParam(from, to);
    if (impersonate !== false) {
        if (to.path === from.path) {
            return;
        }
        next({
            ...to,
            query: {
                // This line is used to retain other query if there is any
                ...to.query,
                ...impersonate,
            },
        });
    } else next();
});

const keepImpersonateParam = (from, to) => {
    if (
        _startsWith(to.name, "hotel") &&
        from.query?.impersonate &&
        //from.name !== to.name &&
        to.query?.impersonate === undefined
    ) {
        return { impersonate: from.query?.impersonate };
    }

    return false;
};

export default router;
