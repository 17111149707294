import { MANAGER_TYPES, ROLES, USER_GRANTS } from "@/core/config/AppConfig";

export default {
    path: "common/",
    component: () => import("@/layout/RouterViewWrapper.vue"),
    name: "common",
    meta: {
        guards: {
            types: [
                MANAGER_TYPES.provincia,
                MANAGER_TYPES.destinazione,
                MANAGER_TYPES.mandamento,
                MANAGER_TYPES.admin,
                MANAGER_TYPES.comune,
            ],
            roles: [
                ROLES.admin,
                ROLES.provincia,
                ROLES.consorzio,
                ROLES.comune,
            ],
        },
    },
    children: [
        {
            // the 404 route, when none of the above matches
            path: "404",
            name: "common-404",
            component: () => import("@/views/crafted/pages/Error404.vue"),
        },
        {
            path: "accommodations/:accommodationId/detail",
            name: "common.accommodations.detail",
            component: () =>
                import("@/views/accommodations/admin/AccommodationDetail.vue"),
            meta: {
                guards: {
                    types: [
                        MANAGER_TYPES.provincia,
                        MANAGER_TYPES.destinazione,
                        MANAGER_TYPES.mandamento,
                        MANAGER_TYPES.admin,
                    ],
                    roles: [ROLES.admin, ROLES.provincia, ROLES.consorzio],
                },
            },
        },
        {
            path: "licenses/:licenseId",
            name: "common.licenses.detail",
            component: () => import("@/views/licence/LicenseDetail.vue"),
            meta: {
                guards: {
                    types: [
                        MANAGER_TYPES.provincia,
                        MANAGER_TYPES.destinazione,
                        MANAGER_TYPES.mandamento,
                        MANAGER_TYPES.admin,
                        MANAGER_TYPES.comune,
                    ],
                    roles: [
                        ROLES.admin,
                        ROLES.provincia,
                        ROLES.consorzio,
                        ROLES.comune,
                    ],
                },
            },
        },
    ],
};
