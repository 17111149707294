import {ElMessage} from "element-plus";


export const successToast = (message = 'Operazione andata a buon fine!', otherParams = {}) => {
    ElMessage({
        message,
        type: "success",
        duration: 3000,
        ...otherParams
    });
}

export const errorToast = (message = 'Ops! Si è verificato un errore', otherParams = {}, error = false) => {
    if (error)
        console.error(error);
    ElMessage({
        message,
        type: "error",
        duration: 3000,
        ...otherParams
    });
}

export const toast = (message: string, type: 'success' | 'error' | 'warning' = 'success', otherParams = {}) => {
    ElMessage({
        message,
        type,
        duration: 3000,
        ...otherParams
    });
}
