import router from "@/router";
import store from "@/store";
import { hasPermission } from "@/core/helpers/permission";
import { Guard, RoleGuard } from "@/router/types";

export const navigate = (name, params = {}) => {
    const fullRouteName = getRouterWithManagerType(name);

    if (router.hasRoute(fullRouteName))
        router.push({
            name: fullRouteName,
            params,
        });
};

export const canAccessRoute = (name) =>
    router.hasRoute(getRouterWithManagerType(name));

export const getRouterWithManagerType = (name) =>
    store.getters.currentManagerBaseRoute + "." + name;

export const checkGrant = (routeName, params = {}) => {
    return checkGuard(
        getRouteGuards(routeName, params),
        store.getters.currentManager,
        params
    );
};

export const getRouteGuards = (routeName, params = {}) => {
    const route = router.resolve({ name: routeName, params: params });
    const guards = (route?.meta?.guards || {}) as Guard;
    if (!guards?.types || !guards?.roles) {
        (route?.matched || []).reverse().forEach((item) => {
            const itemGuards = (item.meta?.guards || {}) as Guard;
            if (!guards.types) {
                guards.types = itemGuards?.types;
            }
            if (!guards.roles) {
                guards.roles = itemGuards?.roles;
            }
        });
    }

    return guards;
};

/**
 * Controlla se il manager corrente ha i permessi necessari per accedere ad una determinata rotta.
 *
 * @param {Guard} guards - La guard che contiene i tipi, i ruoli e in alcuni casi anche il frant che hanno accesso alla rotta.
 * @param {Object} currentManager - The current manager object containing the role and type of the current manager.
 * @param {Object} params - The parameters that might be needed for checking the permissions.
 *
 * @returns {boolean} - Returns true if the current manager has the necessary permissions, false otherwise.
 */
export const checkGuard = (guards: Guard, currentManager, params): boolean => {
    // Se non ci sono guardie o non c'è un manager corrente, ritorna true.
    if (!guards || !currentManager) return true;

    // Controlla se il tipo del manager corrente è permesso.
    const isManageTypeAllowed = guards.types.includes(currentManager.type);

    // Se non ci sono ruoli nelle guardie, setta isRoleAllowed come true.
    let isRoleAllowed = !guards.roles;

    // Se ci sono ruoli nelle guardie, controlla se il ruolo del manager corrente è permesso.
    if (!isRoleAllowed) {
        for (let i = 0; i < guards.roles.length; i++) {
            //Il ruolo può essere un oggetto o una stringa.
            // Se è un oggetto ha definito il grant specifico per quel ruolo.
            let role = guards.roles[i];
            let grant: false | string = false;

            // Se il ruolo è un oggetto, estrai il grant e il ruolo.
            if (typeof role === "object") {
                grant = role.grant;
                role = role.role;

                // Se il ruolo o il grant non sono definiti, lancia un errore perchè la guard è mal configurata.
                if (!role || !grant) {
                    console.error(
                        "Ruolo o grant non definiti",
                        guards,
                        role,
                        grant
                    );
                    throw new Error("Ruolo o grant non definiti");
                }
            }

            // Se il ruolo del manager corrente è uguale al ruolo nella guardia e non c'è un grant o
            // il manager corrente ha il grant, setta isRoleAllowed come true.
            if (
                role === currentManager.role &&
                (!grant || hasPermission(grant, params))
            ) {
                isRoleAllowed = true;
                break;
            }
        }
    }

    let hasGrant = true;
    // Se è presente anche il campo grant nella guardia il grant è applicato a tutti i ruoli
    if (guards.grant) {
        hasGrant = hasPermission(guards.grant, params);
    }

    // Se il tipo del manager corrente è permesso e il ruolo del manager corrente è permesso, ritorna false.
    return isManageTypeAllowed && isRoleAllowed && hasGrant;
};
