import AbitFormBuilder from "./abit-form-builder/AbitFormBuilder.vue";
import AbitTextInput from "./AbitTextInput.vue";
import AbitPasswordInput from "./AbitPasswordInput.vue";
import AbitSelect from "./AbitSelect.vue";
import AbitDatePicker from "./AbitDatePicker.vue";
import AbitRadio from "./AbitRadio.vue";
import AbitSwitch from "./AbitSwitch.vue";
import AbitCheckList from "./AbitCheckList.vue";
import AbitCities from "./AbitCities.vue";
import AbitImageUpload from "./AbitImageUpload.vue";
import AbitDropAutocomplete from "./AbitDropAutocomplete.vue";
import AbitCheckBox from "./AbitCheckBox.vue";
import AbitNumberInput from "./AbitNumberInput.vue";
import AbitSpan from "./AbitSpan.vue";
import AbitAccommodationTypes from "./AbitAccommodationTypes.vue";
import AbitEditor  from "./abit-editor/AbitEditor.vue";

//TODO: Refactor con wrapper comune
export default {
    AbitFormBuilder,
    AbitDatePicker,
    AbitRadio,
    AbitSelect,
    AbitSwitch,
    AbitTextInput,
    AbitEditor,
    AbitPasswordInput,
    AbitCheckList,
    AbitImageUpload,
    AbitDropAutocomplete,
    AbitCheckBox,
    AbitCities,
    AbitNumberInput,
    AbitSpan,
    AbitAccommodationTypes,
};
