<template>
    <div class="loader-screen">
        <div class="loader-screen-content">
            <div class="jannik-spinner">
                <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Caricamento...</span>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.loader-screen {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgb(255 255 255 / 38%);

    .loader-screen-content {
        position: relative;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        .jannik-spinner {
        }
    }
}
</style>
