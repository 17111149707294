import LayoutConfigTypes from "@/core/config/LayoutConfigTypes";
/*
const config: LayoutConfigTypes = {
  themeName: "Metronic",
  themeVersion: "8.0.35",
  demo: "demo1",
  main: {
    type: "default",
    primaryColor: "#009EF7",
    logo: {
      dark: "media/logos/logo-1-dark.svg",
      light: "media/logos/logo-1.svg",
    },
  },
  illustrations: {
    set: "sketchy-1",
  },
  loader: {
    logo: "media/logos/logo-1-dark.svg",
    display: true,
    type: "default",
  },
  scrollTop: {
    display: true,
  },
  header: {
    display: true,
    menuIcon: "font",
    width: "fluid",
    fixed: {
      desktop: true,
      tabletAndMobile: true,
    },
  },
  toolbar: {
    display: true,
    width: "fluid",
    fixed: {
      desktop: true,
      tabletAndMobile: true,
    },
  },
  aside: {
    display: true,
    theme: "dark",
    fixed: true,
    menuIcon: "svg",
    minimized: false,
    minimize: true,
    hoverable: true,
  },
  content: {
    width: "fixed",
  },
  footer: {
    width: "fluid",
  },
};
*/
const config: LayoutConfigTypes = {
    themeName: "Metronic",
    themeVersion: "8.0.35",
    demo: "demo1",
    main: {
        type: "default",
        primaryColor: "#009EF7",
        logo: {
            //dark: "media/logos/logo-1-dark.svg",
            dark: "media/abit/abit.png",
            light: "media/abit/abit.png",
        },
    },
    illustrations: {
        set: "unitedpalms-1",
    },
    loader: {
        logo:"media/abit/abit_logo.png",
        display: true,
        type: "spinner-logo",
    },
    scrollTop: {
        display: true,
    },
    header: {
        display: true,
        menuIcon: "font",
        width: "fixed",
        fixed: {
            desktop: true,
            tabletAndMobile: true,
        },
    },
    toolbar: {
        display: true,
        width: "fixed",
        fixed: {
            desktop: true,
            tabletAndMobile: true,
        },
    },
    aside: {
        display: true,
        theme: "light",
        fixed: true,
        menuIcon: "font",
        minimized: false,
        minimize: true,
        hoverable: true,
    },
    content: {
        width: "fixed",
    },
    footer: {
        width: "fluid",
    },
};
export default config;
