enum Actions {
    // action types
    ADD_BODY_CLASSNAME = "addBodyClassName",
    REMOVE_BODY_CLASSNAME = "removeBodyClassName",
    ADD_BODY_ATTRIBUTE = "addBodyAttribute",
    REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
    ADD_CLASSNAME = "addClassName",
    VERIFY_AUTH = "verifyAuth",
    LOGIN = "login",
    LOGOUT = "logout",
    REGISTER = "register",
    UPDATE_USER = "updateUser",
    FORGOT_PASSWORD = "forgotPassword",
    SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
    SET_TOOLBAR_TYPE = "setToolBarType",
    SET_TOOLBAR_PROPS = "setToolBarPropsAction",

    VERIFY_GRANT = "verifyGrant",

    SET_LICENSES_ACTION = "setLicensesAction",
    SET_TAXES_ACTION = "setTaxesAction",
    SET_SELECTED_LICENSE_ACTION = "setSelectedLicensesAction",
    SET_ISTAT_LOADING_ACTION = "setIstatLoadingAction",
    SET_ISTAT_CLOSINGMONTHALLOWED_ACTION = "setIstatClosingMonthAllowedAction",
    SET_ISTAT_CURRENTMONTHCLOSED_ACTION = "setIstatCurrentMonthClosedAction",
    SET_ISTAT_REFRESH_ACTION = "setIstatRefreshAction",
    SET_ISTAT_FILTERS_ACTION = "setIstatFiltersAction",
    SET_ISTAT_CURRDATE_ACTION = "setIstatCurrDateAction",
    SET_ISTAT_LASTCLOSEDMONTH_ACTION = "setIstatLastClosedMonthAction",
    IMPERSONATE = "doImpersona",
}

enum Mutations {
    // mutation types
    SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
    PURGE_AUTH = "logOut",
    SET_AUTH = "setAuth",
    SET_USER = "setUser",
    SET_PASSWORD = "setPassword",
    SET_ERROR = "setError",
    SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
    SET_LAYOUT_CONFIG = "setLayoutConfig",
    RESET_LAYOUT_CONFIG = "resetLayoutConfig",
    OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
    OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
    SET_MANAGER = "setCurrentManager",
    SET_TOOLBAR_PROPS = "setToolBarProps",
    IMPERSONATE = "setImpersona",
    SET_ROLE = "setUserRole",

    SET_LICENSES_MUTATION = "setLicensesMutation",
    SET_TAXES_MUTATION = "setTaxesMutation",
    SET_SELECTED_LICENSE_MUTATION = "setSelectedLicenseMutation",
    SET_ISTAT_REFRESH_MUTATION = "setLastRefreshMutation",
    SET_ISTAT_LOADING_MUTATION = "setIstatLoadingMutation",
    SET_ISTAT_CLOSINGMONTHALLOWED_MUTATION = "setIstatClosingMonthAllowedMutation",
    SET_ISTAT_CURRENTMONTHCLOSED_MUTATION = "setIstatCurrentMonthClosedMutation",
    SET_ISTAT_FILTERS_MUTATION = "setIstatFiltersMutation",
    SET_ISTAT_GUESTS_MUTATION = "setIstatGuestsMutation",
    SET_ISTAT_CURRDATE_MUTATION = "setIstatCurrDateMutation",
    SET_ISTAT_LASTCLOSEDMONTH_MUTATION = "setIstatLastClosedMonthMutation",
}

enum ToolbarTypes {
    // mutation types
    DEFAULT = "Toolbar",
    ACCOMMODATIONS = "ToolbarAccommodations",
    APPLICATIONS = "ToolbarApplications",
    GUESTS = "ToolbarGuests",
    GUESTS_C59 = "ToolbarC59",
    USERS = "ToolbarUsers",
    PMS = "ToolbarPMS",
    ATTRACTION = "ToolbarAttraction",
    TAX_RATES = "ToolbarTaxRates",
    TAX_SETUP = "ToolbarTaxSetup",
    TAX_EXEMPTION = "ToolbarTaxExemption",
    ALERT = "ToolbarAlert",
    EMAIL = "ToolbarEmail",
    EMAIL_TEMPLATE = "ToolbarEmailTemplate",
    STUDI_SETTORE = "ToolbarStudiSettore",
}

export { Actions, Mutations, ToolbarTypes };
