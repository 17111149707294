export const parseBackendErrors = (error, setError) => {
    if (error?.response?.status === 422) {
        const errors = error?.response?.data?.errors;
        setError(errors);
    }
    console.error(error);
};

export const removeEmptyValues = (object) => {
    for (const key in object) {
        const value = object[key];
        if (value === null || value === undefined || value === "") {
            delete object[key];
        }
    }

    return object;
};
