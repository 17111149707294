import AbitButton from "./Button.vue";
import Card from "./card/Card.vue";
import Notice from "./Notice.vue";
import Modal from "./Modal.vue";
import AbitLink from "./AbitLink.vue";

import Form from "./form";

export default {
    AbitLink,
    AbitButton,
    Card,
    Notice,
    Modal,
    ...Form,
};
