import { Modal } from "bootstrap";
import { ElMessage, ElMessageBox } from "element-plus";
import {
    ElMessageBoxOptions,
    MessageBoxState,
} from "element-plus/es/components/message-box/src/message-box.type";
import { AxiosResponse } from "axios";
import { h } from "vue";
import AbitFormBuilder from "@/components/global/form/abit-form-builder/AbitFormBuilder.vue";
import { IFormBuilderProps } from "@/components/global/form/types";

const showModal = (modalId: string): void => {
    const modalEl = document.getElementById(modalId);
    const myModal = Modal.getOrCreateInstance(modalEl);

    myModal.show();
};

const hideModal = (modalId: string | HTMLElement | null): void => {
    if (modalId === null) return;

    const modalEl =
        typeof modalId === "string"
            ? document.getElementById(modalId)
            : modalId;

    const myModal = Modal.getInstance(modalEl);
    if (!myModal) return;

    myModal.hide();
};

const removeModalBackdrop = (): void => {
    if (document.querySelectorAll(".modal-backdrop.fade.show").length) {
        document
            .querySelectorAll(".modal-backdrop.fade.show")
            .forEach((item) => {
                item.remove();
            });
    }
};

const scrollToTop = (): void => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

const inputDialog = (
    title: string,
    label: string,
    apiCall: (value: any, instance: MessageBoxState) => Promise<AxiosResponse>,
    success?: (data: any, value: any) => any,
    options: ElMessageBoxOptions = {},
    error?: () => any
) => {
    ElMessageBox.prompt(label, title, {
        confirmButtonText: "OK",
        cancelButtonText: "Annulla",
        inputType: "text",
        inputValidator: (value) => (value ? true : "Campo richiesto"),
        ...options,
        beforeClose: (action, instance, done) => {
            if (action === "cancel") return done();
            instance.confirmButtonLoading = true;

            apiCall(instance.inputValue, instance)
                .then(({ data }) => {
                    ElMessage({
                        type: "success",
                        message: data.message,
                    });
                    if (success) success(data, instance.inputValue);
                    done();
                })
                .finally(() => {
                    instance.confirmButtonLoading = false;
                    if (error) error();
                });
        },
    });
};

const formDialog = (
    formBuilderProps: IFormBuilderProps & { onSubmit?: (data: any) => any },
    title = "Compila form",
    options?: {
        onClose?: () => void;
        onCloseCatch?: () => void;
        inputProps?: ElMessageBoxOptions;
        appContext?: any;
    }
) => {
    return ElMessageBox(
        {
            ...(options?.inputProps || {}),
            title,
            showConfirmButton: false,
            message: () =>
                h(AbitFormBuilder, {
                    handled: true,
                    ...formBuilderProps,
                }),
        },
        options?.appContext || null
    );
};

const closeDialog = () => ElMessageBox.close();

const confirmDialog = (
    message,
    title = "Vuoi procedere con l'operazione?",
    type: "" | "success" | "warning" | "info" | "error" = "warning"
) => {
    return ElMessageBox.confirm(message, title, {
        confirmButtonText: "OK",
        cancelButtonText: "Annulla",
        type,
    });
};

export {
    removeModalBackdrop,
    hideModal,
    showModal,
    scrollToTop,
    inputDialog,
    formDialog,
    closeDialog,
    confirmDialog,
};
