import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import router from "@/router";
import { ElMessage } from "element-plus";
import { errorToast } from "@/core/helpers/notification";

export interface Response {
    content: any;
    message: string;
    status: "OK" | "ERROR";
}

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
    /**
     * @description property to share vue instance
     */
    public static vueInstance: App;
    public static showError = true;

    public static noToast() {
        ApiService.showError = false;
        return this;
    }

    /**
     * @description initialize vue axios
     */
    public static init(app: App<Element>) {
        ApiService.vueInstance = app;
        ApiService.vueInstance.use(VueAxios, axios);
        ApiService.vueInstance.axios.defaults.baseURL =
            process.env.VUE_APP_API_URL;

        ApiService.vueInstance.axios.interceptors.request.use(function (
            successfulReq
        ) {
            // Aggiungo l'impersonate a tutte le chiamate qualora sia presente come parametro in vue-router
            if (router.currentRoute.value?.query?.impersonate)
                successfulReq.params = {
                    ...successfulReq.params,
                    impersonate: router.currentRoute.value.query.impersonate,
                };
            return successfulReq;
        });

        ApiService.vueInstance.axios.interceptors.response.use(
            function (response) {
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                return response;
            },
            function (error) {
                if (error?.response?.status === 404) {
                    // pagina 404
                    router.push({ name: "common-404" });
                }
                if (error?.response?.status === 401) {
                    // Sessone scaduta
                    router.push({ name: "login" });
                } else {
                    if (ApiService.showError)
                        errorToast(
                            error?.response?.data?.message ||
                                error?.response?.message ||
                                "Ops! Si è verificato un'errore",
                            { grouping: true }
                        );
                }

                ApiService.showError = true;

                return Promise.reject(error);
            }
        );
    }

    /**
     * @description set the default HTTP request headers
     */
    public static setHeader(): void {
        ApiService.vueInstance.axios.defaults.headers.common[
            "Authorization"
        ] = `Token ${JwtService.getToken()}`;
        ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
            "application/json";
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param params: any
     * @param options: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static query(
        resource: string,
        params: any,
        options: AxiosRequestConfig = {}
    ): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.get(resource, {
            ...options,
            params,
        });
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param slug: string
     * @param params
     * @returns Promise<AxiosResponse>
     */
    public static get(
        resource: string,
        slug = "" as string,
        params = {}
    ): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.get(`${resource}/${slug}`, {
            params,
        });
    }

    /**
     * @description set the POST HTTP request
     * @returns Promise<AxiosResponse>
     * @param resource
     * @param data
     * @param params
     */
    public static post(
        resource: string,
        data: any,
        params: AxiosRequestConfig = {}
    ): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.post(`${resource}`, data, params);
    }

    /**
     * @description send the UPDATE HTTP request
     * @param resource: string
     * @param slug: string
     * @param params: AxiosRequestConfig
     * @param options
     * @returns Promise<AxiosResponse>
     */
    public static update(
        resource: string,
        slug: string | number,
        params: any = {},
        options: AxiosRequestConfig = {}
    ): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.patch(
            `${resource}/${slug}`,
            params,
            options
        );
    }

    /**
     * @description Send the PUT HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @param options
     * @returns Promise<AxiosResponse>
     */
    public static put(
        resource: string,
        params: any = {},
        options: AxiosRequestConfig = {}
    ): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.put(`${resource}`, params, options);
    }

    /**
     * @description Send the DELETE HTTP request
     * @param resource: string
     * @returns Promise<AxiosResponse>
     */
    public static delete(resource: string): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.delete(resource);
    }

    public static dummy(resource: string, params: any = {}): Promise<any> {
        return new Promise((resolve) => resolve(200));
    }
}

export default ApiService;
