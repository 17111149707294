import {MANAGER_TYPES_BASE_ROUTE, MANAGER_TYPES_LABEL} from "@/core/config/AppConfig";

export const managerName = (id) => {
    return MANAGER_TYPES_LABEL[id] || "NA";
};



export const searchCurrentManager = (managers, id) => managers.find((i) => i.id === id);

export const getManagerTypeBaseRoute = (managerType) => MANAGER_TYPES_BASE_ROUTE[managerType]
