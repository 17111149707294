export default {
    path: "profile/",
    component: () => import("@/layout/RouterViewWrapper.vue"),
    name: "profile",
    meta: {
    },
    children: [
        {
            // the 404 route, when none of the above matches
            path: "my-privacy",
            name: "profile.my-privacy",
            component: () => import("@/views/MyPrivacy.vue"),
        },
        {
            // the 404 route, when none of the above matches
            path: "my-accommodations",
            name: "profile.my-accommodations",
            component: () => import("@/views/MyAccommodations.vue"),
        },
        {
            // the 404 route, when none of the above matches
            path: "my-data",
            name: "profile.my-data",
            component: () => import("@/views/MyData.vue"),
        },
        {
            // the 404 route, when none of the above matches
            path: "change-password",
            name: "profile.change-password",
            component: () => import("@/views/ChangePassword.vue"),
        }, 
    ],
};
